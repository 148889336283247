import React from "react";

import Layout from "../components/common/layout"
import SEO from "../components/common/seo"

import { useIntl } from "gatsby-plugin-intl"

const Privacy = () => {

  const intl = useIntl()

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "site.title" })} />
      <div className="terms-wrapper">
        <h2 className="header">Privacy Statement Kaigo IT Solutions</h2>
        <div className="wrapper">
          <div>
            <p>Kaigo IT Solutions, gevestigd aan Blaak 520, 3011TA Rotterdam, is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.</p>
            <p>Contactgegevens:
            <br /><a className="link link-active" target="_blank" href="https://kaigo.io">https://kaigo.io</a>
            <br />Blaak 520
            <br />3011TA Rotterdam
            <br />0616219340</p>
            <p>Mithun Martin Mendez is de Directeur van Kaigo IT Solutions Hij/zij is te bereiken via <a className="link link-active" href="mailto:mithun@kaigo.io">mithun@kaigo.io</a></p>

            <h4 className="header">Persoonsgegevens die wij verwerken</h4>
            <p>Kaigo IT Solutions verwerkt geen persoonsgegevens omdat op onze site geen persoonsgegevens achter gelaten kunnen worden. Ook gebruiken we geen social media plugins.</p>

            <h4 className="header">Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</h4>
            <p>Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn bij de online activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming. Als je er van overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via info@kaigo.io, dan verwijderen wij deze informatie.</p>

            <h4 className="header">Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken</h4>
            <p>Kaigo IT Solutions verwerkt jouw persoonsgegevens voor de volgende doelen:</p>
            <p>- Om goederen en diensten bij je af te leveren</p>

            <h4 className="header">Geautomatiseerde besluitvorming</h4>
            <p>Kaigo IT Solutions neemt niet op basis van geautomatiseerde verwerkingen besluiten over zaken die (aanzienlijke) gevolgen kunnen hebben voor personen. Het gaat hier om besluiten die worden genomen door computerprogramma's of -systemen, zonder dat daar een mens (bijvoorbeeld een medewerker van Kaigo IT Solutions) tussen zit.</p>
            <p>Kaigo IT Solutions gebruikt de volgende computerprogramma's of -systemen:</p>
            <p>Wij maken gebruik van het boekhoudsysteem Gekko, In dit systeem leggen wij de persoonsgegevens van onze klanten vast. Dit systeem is niet toegankelijk door derden.</p>

            <h4 className="header">Hoe lang we persoonsgegevens bewaren</h4>
            <p>Kaigo IT Solutions bewaart je persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor je gegevens worden verzameld. Wij hanteren de volgende bewaartermijnen voor de volgende (categorieën) van persoonsgegevens:</p>
            <p>persoonsgegevens
            <br />Personalia
            <br />Adres</p>
            <p>Wij bewaren deze gegevens zolang je klant bij ons bent</p>

            <h4 className="header">Delen van persoonsgegevens met derden</h4>
            <p>Kaigo IT Solutions verstrekt uitsluitend aan derden en alleen als dit nodig is voor de uitvoering van onze overeenkomst met jou of om te voldoen aan een wettelijke verplichting. </p>

            <h4 className="header">Cookies, of vergelijkbare technieken, die wij gebruiken</h4>
            <p>Kaigo IT Solutions gebruikt alleen technische en functionele cookies. En analytische cookies die geen inbreuk maken op je privacy. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen op jouw computer, tablet of smartphone. De cookies die wij gebruiken zijn noodzakelijk voor de technische werking van de website en jouw gebruiksgemak. Ze zorgen ervoor dat de website naar behoren werkt en onthouden bijvoorbeeld jouw voorkeursinstellingen. Ook kunnen wij hiermee onze website optimaliseren. Je kunt je afmelden voor cookies door je internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kun je ook alle informatie die eerder is opgeslagen via de instellingen van je browser verwijderen.</p>

            <h4 className="header">Gegevens inzien, aanpassen of verwijderen </h4>
            <p>Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heb je het recht om je eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van jouw persoonsgegevens door Kaigo IT Solutions en heb je het recht op gegevensoverdraagbaarheid. Dat betekent dat je bij ons een verzoek kan indienen om de persoonsgegevens die wij van jou beschikken in een computerbestand naar jou of een ander, door jou genoemde organisatie, te sturen. </p>
            <p>Je kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van je persoonsgegevens of verzoek tot intrekking van je toestemming of bezwaar op de verwerking van jouw persoonsgegevens sturen naar info@kaigo.io.</p>
            <p>Om er zeker van te zijn dat het verzoek tot inzage door jou is gedaan, vragen wij jou een kopie van je identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie je pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van je privacy. We reageren zo snel mogelijk, maar binnen vier weken, op jouw verzoek .</p>
            <p>Kaigo IT Solutions wil je er tevens op wijzen dat je de mogelijkheid hebt om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link: </p>
            <a className="link link-active link-out-text" target="_blank" href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons">https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons</a>

            <h4 className="header">Hoe wij persoonsgegevens beveiligen</h4>
            <p>Kaigo IT Solutions neemt de bescherming van jouw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als jij het idee hebt dat jouw gegevens toch niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op met onze klantenservice of via <a className="link link-active" href="mailto:info@kaigo.io">info@kaigo.io</a></p>

          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Privacy